
  import { Component, Prop } from 'vue-property-decorator'
  import Cell from './cell'

@Component
  export default class IconCell extends Cell {
  @Prop({ type: String, default: 'large' }) readonly size!: any

  get mainIcon () {
    const { item } = this

    if (!item) return ''

    const { icon } = item

    if (typeof icon === 'string') return icon

    return icon?.main
  }

  get color () {
    const { item } = this

    if (!item) return ''

    const { color } = item
    return color
  }

  get description () {
    const { item } = this

    if (!item) return ''

    const { description } = item

    return description
  }

  get isLarge () {
    const { size } = this
    return size === 'large'
  }

  get isSmall () {
    const { size } = this
    return size === 'small'
  }
  }
